import OwlCarousel from "react-owl-carousel";
import React from "react";
import home from "../../../assets/images/screens/Home.jpg";
import event from "../../../assets/images/screens/Event Screen.jpg";
import organization from "../../../assets/images/screens/Organization.jpg";
import eventCategory from "../../../assets/images/screens/Category.jpg";
import social from "../../../assets/images/screens/Social.jpg";
import search from "../../../assets/images/screens/Search Screen.jpg";

const Main = () => {
  const screen_slider = {
    loop: true,
    margin: 10,
    nav: false,
    autoplay: true,
    smartSpeed: 1500,
    center: true,
    dots: true,
    responsive: {
      0: {
        items: 2,
      },
      600: {
        items: 3,
      },
      1000: {
        items: 5,
      },
    },
  };
  return (
    <>
      <section className="row_am interface_section">
        <div className="container-fluid">
          <div
            className="section_title"
            data-aos="fade-up"
            data-aos-duration="1500"
            data-aos-delay="300"
          >
            <h2>
              Intuitive <span>Design</span>
            </h2>
            <p>
              We pride ourselves in a beautiful and user-friendly interface{" "}
              <br /> so that you can get back to making memories.
            </p>
          </div>
          <div className="screen_slider">
            <OwlCarousel
              id="screen_slider"
              {...screen_slider}
              className="owl-carousel owl-theme owl-loaded owl-drag"
            >
              <div className="item">
                <div className="screen_frame_img">
                  <img src={home} alt="" />
                </div>
              </div>
              <div className="item">
                <div className="screen_frame_img">
                  <img src={event} alt="" />
                </div>
              </div>
              <div className="item">
                <div className="screen_frame_img">
                  <img src={organization} alt="" />
                </div>
              </div>
              <div className="item">
                <div className="screen_frame_img">
                  <img src={eventCategory} alt="image" />
                </div>
              </div>
              <div className="item">
                <div className="screen_frame_img">
                  <img src={social} alt="image" />
                </div>
              </div>
              <div className="item">
                <div className="screen_frame_img">
                  <img src={search} alt="image" />
                </div>
              </div>
            </OwlCarousel>
          </div>
        </div>
      </section>
    </>
  );
};

export default Main;
