import React, { useEffect, useState } from "react";
import { Route, Routes, useLocation, Navigate } from "react-router-dom";
import Navbar from "../component/Navbar/Main";
import Footer from "../component/Footer/Main";
import Home from "../component/Home/Main";
import AboutUs from "../component/AboutUs/Main";
import Review from "../component/Pages/Review/Main";
import Signup from "../component/Signup/Main";
import Faq from "../component/Pages/Faq/Main";
import Pricing from "../component/Pricing/Main";
import Blog from "../component/Blog/Blog/Main";
import BlogSingle from "../component/Blog/BlogSingle/Main";
import Contact from "../component/Contact/Main";
import Verify from "../component/Verify/Main";
import PasswordReset from "../component/PasswordReset/Main";
import NotFound from "../component/Notfound/Main";
import PrivacyPolicy from "../component/PrivacyPolicy/Main";
import TermsOfService from "../component/TermsOfService/Main";
import Event from "../component/Event/Main";
import Organization from "../component//Organization/Main";
import Ambassador from "../component/Ambassador/Main";

const Routing = () => {
  const [homepage, sethomepage] = useState(false);
  const location = useLocation();

  useEffect(() => {
    if (
      location.pathname === "/sign-in" ||
      location.pathname === "/verify" ||
      location.pathname === "/resetPassword"
    ) {
      sethomepage(false);
    } else {
      sethomepage(true);
    }
  }, [location]);

  const [footerpage, setfooterpage] = useState(false);
  useEffect(() => {
    if (
      location.pathname.includes("/verify") ||
      location.pathname.includes("/resetPassword") ||
      location.pathname.includes("/events") ||
      location.pathname.includes("/organizations")
    ) {
      setfooterpage(false);
    } else {
      setfooterpage(true);
    }
  }, [location]);

  useEffect(() => {
    setfooter({ f1: true });
    setnavbar({ n2: true });
    setbrdcum({ b1: true });
  }, []);

  const [footer, setfooter] = useState({ f1: true });
  const [navbar, setnavbar] = useState({ n1: true });
  const [brdcum, setbrdcum] = useState({ n1: true });

  return (
    <>
      {homepage && <Navbar navbar={navbar} />}
      <Routes>
        <Route
          path="/"
          element={
            <Home
              setfooter={setfooter}
              setnavbar={setnavbar}
              setbrdcum={setbrdcum}
            />
          }
        />
        <Route
          path="/about"
          element={<AboutUs setnavbar={setnavbar} brdcum={brdcum} />}
        />

        <Route path="/faq" element={<Faq brdcum={brdcum} />} />
        {/* TODO Add Back
                <Route
          path="/review"
          element={<Review setnavbar={setnavbar} brdcum={brdcum} />}
        />
        <Route path="/signup" element={<Signup brdcum={brdcum} />} />
        <Route path="/verify" element={<Verify brdcum={brdcum} />} />
        <Route
          path="/resetPassword"
          element={<PasswordReset brdcum={brdcum} />}
        />
        <Route path="/pricing" element={<Pricing brdcum={brdcum} />} />
        <Route path="/blog" element={<Blog brdcum={brdcum} />} />
        <Route path="/blog-single" element={<BlogSingle brdcum={brdcum} />} />
                */}
        <Route path="/contact" element={<Contact brdcum={brdcum} />} />
        <Route path="/signup" element={<Signup brdcum={brdcum} />} />
        <Route path="/verify" element={<Verify brdcum={brdcum} />} />
        <Route
          path="/resetPassword"
          element={<PasswordReset brdcum={brdcum} />}
        />
        <Route path="/terms" element={<TermsOfService />} />
        <Route path="/privacy" element={<PrivacyPolicy />} />
        <Route path="/events" element={<Event />} />
        <Route path="/organizations" element={<Organization />} />
        <Route path="/ambassadors" element={<Ambassador />} />
        <Route path="/404" element={<NotFound />} />
        <Route path="*" element={<Navigate to="/404" />} />
      </Routes>
      {footerpage && <Footer footer={footer} />}
    </>
  );
};

export default Routing;
