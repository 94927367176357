import React, { useState, useEffect } from "react";
import Trusted from "../HomeMain/Trusted/Main";
import { Link } from "react-router-dom";
import Bredcrumb from "../Bredcrumb/Main";
import BGImg from "../../assets/images/bread_crumb_bg.png";
import BGImg1 from "../../assets/images/bread_crumb_bg_one.png";
import BGImg2 from "../../assets/images/bread_crumb_bg_two.png";
import about1 from "../../assets/images/about_us_1.png";
import about2 from "../../assets/images/about_us_2.png";
import about3 from "../../assets/images/about_us_3.png";

const Main = ({ brdcum }) => {
  return (
    <>
      <Bredcrumb no={1} title="About Us" tag="About us" bgimg={BGImg} />

      <section className="row_am app_solution_section">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="app_text">
                <div
                  className="section_title"
                  data-aos="fade-up"
                  data-aos-duration="1500"
                  data-aos-delay="100"
                >
                  <h2>
                    <span>Connecting businesses to customers</span> through
                    discoverability.
                  </h2>
                </div>
                <p data-aos="fade-up" data-aos-duration="1500">
                  Knowhere is a centralized platform that allows businesses to
                  better advertise their experiences to their end customers. In
                  turn, users can easily discover, plan for, and attend events
                  in their local community while also having the ability to
                  follow their favorite organizations, check into events, and
                  let their friends know what they want to do.
                </p>
                <p data-aos="fade-up" data-aos-duration="1500">
                  Knowhere's goal is to be the primary platform for businesses
                  to advertise their events and for its users to have a passive
                  experience for discovering and planning events with friends.
                </p>
              </div>
            </div>
            <div className="col-lg-6">
              <div
                className="app_images"
                data-aos="fade-in"
                data-aos-duration="1500"
              >
                <ul>
                  <li>
                    <img src={about1} alt="" width={400} />
                  </li>
                  <li>
                    <img src={about2} alt="" width={220} />
                  </li>
                  <li>
                    <img src={about3} alt="" width={320} />
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="row_am why_we_section" data-aos="fade-in">
        <div className="why_inner">
          <div className="container">
            <div
              className="section_title"
              data-aos="fade-up"
              data-aos-duration="1500"
              data-aos-delay="100"
            >
              <h2>
                <span>What separates us</span> from others
              </h2>
              <p>
                Allowing businesses to have complete control and insight on how
                they <br /> interact with their customers through experiences.
              </p>
            </div>
            <div className="row">
              <div className="col-md-6 col-lg-3">
                <div
                  className="why_box"
                  data-aos="fade-up"
                  data-aos-duration="1500"
                  data-aos-delay="100"
                >
                  <div className="text">
                    <h3>Easy Discoverability</h3>
                    <p>
                      Users can discover, plan for, and attend experiences all
                      in one place due to an intuitive and feature rich design.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-lg-3">
                <div
                  className="why_box"
                  data-aos="fade-up"
                  data-aos-duration="1500"
                  data-aos-delay="200"
                >
                  <div className="text">
                    <h3>Passive Experience</h3>
                    <p>
                      Knowhere allows for users to passively discover
                      experiences, simplifying the process of "what should we
                      do?".
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-lg-3">
                <div
                  className="why_box"
                  data-aos="fade-up"
                  data-aos-duration="1500"
                  data-aos-delay="300"
                >
                  <div className="text">
                    <h3>Better Insight</h3>
                    <p>
                      Connecting businesses directly to customers allows for
                      better analytics that can help grow businesses.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-lg-3">
                <div
                  className="why_box"
                  data-aos="fade-up"
                  data-aos-duration="1500"
                  data-aos-delay="400"
                >
                  <div className="text">
                    <h3>Complete Control</h3>
                    <p>
                      Unlike any other platform, Knowhere gives businesses full
                      control on how they promote their experiences.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* TODO Add Back Maybe 
      <section className="row_am about_app_section about_page_sectino">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div
                className="abt_img"
                data-aos="fade-in"
                data-aos-duration="1500"
              >
                <img src="assets/images/about_main.png" alt="image" />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="about_text">
                <div
                  className="section_title"
                  data-aos="fade-up"
                  data-aos-duration="1500"
                  data-aos-delay="100"
                >
                  <h2>
                    {" "}
                    <span>We focus on quality,</span> never focus on quantity
                  </h2>
                  <p>
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry lorem Ipsum has been the industrys
                    standard dummy text ever since the when an unknown printer
                    took a galley of type and.
                  </p>
                </div>
                <ul
                  className="app_statstic"
                  id="counter"
                  data-aos="fade-in"
                  data-aos-duration="1500"
                >
                  <li>
                    <div className="icon">
                      <img src="assets/images/download.png" alt="image" />
                    </div>
                    <div className="text">
                      <p>
                        <span className="counter-value" data-count="17">
                          0
                        </span>
                        <span>M+</span>
                      </p>
                      <p>Download</p>
                    </div>
                  </li>
                  <li>
                    <div className="icon">
                      <img src="assets/images/followers.png" alt="image" />
                    </div>
                    <div className="text">
                      <p>
                        <span className="counter-value" data-count="08">
                          0{" "}
                        </span>
                        <span>M+</span>
                      </p>
                      <p>Followers</p>
                    </div>
                  </li>
                  <li>
                    <div className="icon">
                      <img src="assets/images/reviews.png" alt="image" />
                    </div>
                    <div className="text">
                      <p>
                        <span className="counter-value" data-count="2300">
                          1500
                        </span>
                        <span>+</span>
                      </p>
                      <p>Reviews</p>
                    </div>
                  </li>
                  <li>
                    <div className="icon">
                      <img src="assets/images/countries.png" alt="image" />
                    </div>
                    <div className="text">
                      <p>
                        <span className="counter-value" data-count="150">
                          0
                        </span>
                        <span>+</span>
                      </p>
                      <p>Countries</p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
  */}
      {/* TODO Add Back 
      <section className="row_am experts_team_section">
        <div className="container">
          <div
            className="section_title"
            data-aos="fade-up"
            data-aos-duration="1500"
            data-aos-delay="100"
          >
            <h2>
              {" "}
              Meet the <span> team </span>
            </h2>
            <p>
              Lorem Ipsum is simply dummy text of the printing and typese tting{" "}
              <br /> indus orem Ipsum has beenthe standard dummy.
            </p>
          </div>
          <div className="row">
            <div
              className="col-md-6 col-lg-3"
              data-aos="fade-up"
              data-aos-duration="1500"
              data-aos-delay="100"
            >
              <div className="experts_box">
                <img src="assets/images/experts_01.png" alt="image" />
                <div className="text">
                  <h3>Steav Joe</h3>
                  <span>CEO & Co-Founder</span>
                  <ul className="social_media">
                    <li>
                      <Link to="#">
                        <i className="icofont-facebook"></i>
                      </Link>
                    </li>
                    <li>
                      <Link to="#">
                        <i className="icofont-twitter"></i>
                      </Link>
                    </li>
                    <li>
                      <Link to="#">
                        <i className="icofont-instagram"></i>
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div
              className="col-md-6 col-lg-3"
              data-aos="fade-up"
              data-aos-duration="1500"
              data-aos-delay="200"
            >
              <div className="experts_box">
                <img src="assets/images/experts_02.png" alt="image" />
                <div className="text">
                  <h3>Mark Dele</h3>
                  <span>Co-Founder</span>
                  <ul className="social_media">
                    <li>
                      <Link to="#">
                        <i className="icofont-facebook"></i>
                      </Link>
                    </li>
                    <li>
                      <Link to="#">
                        <i className="icofont-twitter"></i>
                      </Link>
                    </li>
                    <li>
                      <Link to="#">
                        <i className="icofont-instagram"></i>
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div
              className="col-md-6 col-lg-3"
              data-aos="fade-up"
              data-aos-duration="1500"
              data-aos-delay="300"
            >
              <div className="experts_box">
                <img src="assets/images/experts_03.png" alt="image" />
                <div className="text">
                  <h3>Jolley Sihe</h3>
                  <span>Business Developer</span>
                  <ul className="social_media">
                    <li>
                      <Link to="#">
                        <i className="icofont-facebook"></i>
                      </Link>
                    </li>
                    <li>
                      <Link to="#">
                        <i className="icofont-twitter"></i>
                      </Link>
                    </li>
                    <li>
                      <Link to="#">
                        <i className="icofont-instagram"></i>
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div
              className="col-md-6 col-lg-3"
              data-aos="fade-up"
              data-aos-duration="1500"
              data-aos-delay="400"
            >
              <div className="experts_box">
                <img src="assets/images/experts_04.png" alt="image" />
                <div className="text">
                  <h3>Rimy Nail</h3>
                  <span>Marketing & Sales</span>
                  <ul className="social_media">
                    <li>
                      <Link to="#">
                        <i className="icofont-facebook"></i>
                      </Link>
                    </li>
                    <li>
                      <Link to="#">
                        <i className="icofont-twitter"></i>
                      </Link>
                    </li>
                    <li>
                      <Link to="#">
                        <i className="icofont-instagram"></i>
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
*/}
      <section className="row_am query_section">
        <div
          className="query_inner"
          data-aos="fade-in"
          data-aos-duration="1500"
        >
          <div className="container">
            <span className="banner_shape1">
              {" "}
              <img src="assets/images/banner-shape1.png" alt="image" />{" "}
            </span>
            <span className="banner_shape2">
              {" "}
              <img src="assets/images/banner-shape2.png" alt="image" />{" "}
            </span>
            <span className="banner_shape3">
              {" "}
              <img src="assets/images/banner-shape3.png" alt="image" />{" "}
            </span>

            <div className="section_title">
              <h2>Have a question ?</h2>
              <p>
                Feel free to reach out to contact@knowheresocial.com <br /> with
                any questions you may have!
              </p>
            </div>
            <Link to="/contact" className="btn white_btn">
              CONTACT US NOW
            </Link>
          </div>
        </div>
      </section>
    </>
  );
};

export default Main;
