import React from "react";
import img1 from "../../../assets/images/check.png";
import img2 from "../../../assets/images/follow.png";
import img3 from "../../../assets/images/organization.png";
import img4 from "../../../assets/images/share.png";
import img5 from "../../../assets/images/features_frame.png";
import home from "../../../assets/images/screens/Home.jpg";

const Main = ({ video }) => {
  return (
    <section className="row_am features_section" id="features">
      <div className="container">
        <div
          className="section_title"
          data-aos="fade-up"
          data-aos-duration="1500"
          data-aos-delay="100"
        >
          <h2>
            <span>Plan</span> with a click of a button
          </h2>
          <p>
            Easily discover new experiences in your area
            <br /> and never wonder what you should do again.
          </p>
        </div>
        <div className="feature_detail">
          <div className="left_data feature_box">
            <div
              className="data_block"
              data-aos="fade-right"
              data-aos-duration="1500"
            >
              <div className="icon">
                <img src={img1} alt="" />
              </div>
              <div className="text">
                <h4>Check Into Experiences</h4>
                <p>
                  Keep track of the experiences you want to go to, all in one
                  spot.
                </p>
              </div>
            </div>
            <br />
            <br />
            <br />
            <br />
            <div
              className="data_block"
              data-aos="fade-right"
              data-aos-duration="1500"
            >
              <div className="icon">
                <img src={img2} alt="" />
              </div>
              <div className="text">
                <h4>Follow Friends</h4>
                <p>
                  Easily see which friends are available and invite them to an
                  experience.
                </p>
              </div>
            </div>
          </div>
          <div className="right_data feature_box">
            <div
              className="data_block"
              data-aos="fade-left"
              data-aos-duration="1500"
            >
              <div className="icon">
                <img src={img3} alt="" />
              </div>
              <div className="text">
                <h4>Follow Organizations</h4>
                <p>
                  Follow your favorite organizations so you are always in their
                  Know.
                </p>
              </div>
            </div>
            <br />
            <br />
            <br />
            <br />
            <div
              className="data_block"
              data-aos="fade-left"
              data-aos-duration="1500"
            >
              <div className="icon">
                <img src={img4} alt="" />
              </div>
              <div className="text">
                <h4>Share Status</h4>
                <p>
                  Share to your Friends what you want to do to more easily make
                  plans.
                </p>
              </div>
            </div>
          </div>
          <div
            className="feature_img"
            data-aos="fade-up"
            data-aos-duration="1500"
            data-aos-delay="100"
          >
            <img src={home} alt="" width={350} />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Main;
