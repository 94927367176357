import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { HashLink as Link } from "react-router-hash-link";
import logo from "../../assets/images/footer_logo.png";
import logo1 from "../../assets/images/logo.png";

const Main = ({ navbar }) => {
  const location = useLocation();
  const path = location.pathname;

  const [show, setShow] = useState();
  const [show1, setShow1] = useState();
  const [show2, setShow2] = useState();
  const [mobile, setmobile] = useState();

  useEffect(() => {
    window.scroll(0, 0);
  }, [path]);

  return (
    <>
      <>
        <header>
          <div className="container">
            <nav className="navbar navbar-expand-lg">
              <Link to="/" className="navbar-brand">
                <img src={logo1} alt="image" />
              </Link>
              <button
                className="navbar-toggler"
                type="button"
                data-toggle="collapse"
                data-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <div
                  className={`toggle-wrap ${mobile && "active"}`}
                  onClick={() => setmobile(mobile === true ? false : true)}
                >
                  <span className="toggle-bar"></span>
                </div>
              </button>
              <div className="collapse navbar-collapse">
                <ul className="navbar-nav ml-auto">
                  <li className="nav-item">
                    <Link smooth to="/#" className="nav-link">
                      Home
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link smooth to="/#download" className="nav-link">
                      Download
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link smooth to="/#features" className="nav-link">
                      Features
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link to="/about" className="nav-link">
                      About Us
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link to="/faq" className="nav-link">
                      FAQ
                    </Link>
                  </li>
                  <li className="nav-item">
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://dashboard.knowheresocial.com"
                      className="nav-link"
                    >
                      Login
                    </a>
                  </li>
                  <li className="nav-item">
                    <Link to="/signup" className="nav-link dark_btn">
                      GET STARTED
                    </Link>
                  </li>
                  {/*
                  <li className="nav-item">
                    <Link smooth to="#how_it_work" className="nav-link">
                      How it works
                    </Link>
                  </li>
                  <li className="nav-item has_dropdown">
                    <Link to="#" className="nav-link">
                      Pages
                    </Link>
                    <span className="drp_btn">
                      <i className="icofont-rounded-down"></i>
                    </span>
                    <div className="sub_menu">
                      <ul>
                        <li>
                          <Link to="/about">About Us</Link>
                        </li>
                        <li>
                          <Link to="/review">Reviews</Link>
                        </li>
                        <li>
                          <Link to="/contact">Contact Us</Link>
                        </li>
                        <li>
                          <Link to="/faq">Faq</Link>
                        </li>
                        <li>
                          <Link to="/sign-in">Sign In</Link>
                        </li>
                        <li>
                          <Link to="/sign-up">Sign Up</Link>
                        </li>
                        <li>
                          <Link to="/blog">Blog List</Link>
                        </li>
                        <li>
                          <Link to="/blog-single">Blog Single</Link>
                        </li>
                      </ul>
                    </div>
                  </li>
                  <li className="nav-item">
                    <Link to="/pricing" className="nav-link">
                      Pricing
                    </Link>
                  </li>
                  <li className="nav-item has_dropdown">
                    <Link to="#" className="nav-link">
                      Blog
                    </Link>
                    <span className="drp_btn">
                      <i className="icofont-rounded-down"></i>
                    </span>
                    <div className="sub_menu">
                      <ul>
                        <li>
                          <Link to="/blog">Blog List</Link>
                        </li>
                        <li>
                          <Link to="/blog-single">Blog Single</Link>
                        </li>
                      </ul>
                    </div>
                  </li>
                  {/* <li className="nav-item">
                    <Link to="/contact" className="nav-link dark_btn">
                      GET STARTED
                    </Link>
                  </li> */}
                </ul>
              </div>
              <div
                className={`collapse navbar-collapse mobile-screen ${
                  mobile && "show"
                }`}
              >
                <ul className="navbar-nav ml-auto">
                  <li className="nav-item">
                    <Link smooth to="/#" className="nav-link">
                      Home
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link HashLink smooth to="/#download" className="nav-link">
                      Download
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link to="/signup" className="nav-link">
                      Get Started
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link smooth to="/#features" className="nav-link">
                      Features
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link smooth to="/about" className="nav-link">
                      About Us
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link smooth to="/faq" className="nav-link">
                      FAQ
                    </Link>
                  </li>

                  {/* <li className="nav-item">
                    <Link smooth to="#how_it_work" className="nav-link">
                      How it works
                    </Link>
                  </li>
                  <li
                    className="nav-item has_dropdown"
                    onClick={() => setShow1(show1 === true ? false : true)}
                  >
                    <Link to="#" className="nav-link">
                      Pages
                    </Link>
                    <span className="drp_btn">
                      <i className="icofont-rounded-down"></i>
                    </span>
                    <div
                      className="sub_menu"
                      style={{ display: show1 ? "block" : "none" }}
                    >
                      <ul>
                        <li>
                          <Link to="/about">About Us</Link>
                        </li>
                        <li>
                          <Link to="/review">Reviews</Link>
                        </li>
                        <li>
                          <Link to="/contact">Contact Us</Link>
                        </li>
                        <li>
                          <Link to="/faq">FAQ</Link>
                        </li>
                        <li>
                          <Link to="/sign-in">Sign In</Link>
                        </li>
                        <li>
                          <Link to="/sign-up">Sign Up</Link>
                        </li>
                        <li>
                          <Link to="/blog">Blog List</Link>
                        </li>
                        <li>
                          <Link to="/blog-single">Blog Single</Link>
                        </li>
                      </ul>
                    </div>
                  </li>
                  <li className="nav-item">
                    <Link to="/pricing" className="nav-link">
                      Pricing
                    </Link>
                  </li>
                  <li
                    className="nav-item has_dropdown"
                    onClick={() => setShow2(show2 === true ? false : true)}
                  >
                    <Link to="#" className="nav-link">
                      Blog
                    </Link>
                    <span className="drp_btn">
                      <i className="icofont-rounded-down"></i>
                    </span>
                    <div
                      className="sub_menu"
                      style={{ display: show2 ? "block" : "none" }}
                    >
                      <ul>
                        <li>
                          <Link to="/blog">Blog List</Link>
                        </li>
                        <li>
                          <Link to="/blog-single">Blog Single</Link>
                        </li>
                      </ul>
                    </div>
                  </li>
                  <li className="nav-item">
                    <Link to="/contact" className="nav-link">
                      Contact
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link to="/contact" className="nav-link dark_btn">
                      GET STARTED
                    </Link>
                  </li> */}
                </ul>
              </div>
            </nav>
          </div>
        </header>
      </>
    </>
  );
};

export default Main;
