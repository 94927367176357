import React, { useEffect } from "react";
import { Helmet } from "react-helmet-async";

import { useSearchParams } from "react-router-dom";

const Main = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const eventId = searchParams.get("eventId");
  const occurrenceStartTs = searchParams.get("occurrenceStartTs");
  const title = searchParams.get("title");
  const banner = searchParams.get("banner");

  useEffect(() => {
    if (eventId === null) {
      window.location.replace("https://knowheresocial.com");
    } else {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        window.location.replace(
          `knowhere://events?eventId=${eventId}&occurrenceStartTs=${occurrenceStartTs}`
        );
      } else {
        window.location.replace("https://knowheresocial.com");
      }
    }
  }, [eventId, occurrenceStartTs]);

  return (
    <div className="bred_crumb">
      <div className="container">
        <Helmet prioritizeSeoTags>
          <title>{title}</title>
          <meta property="og:title" content={title} />
          <meta property="og:image" content={banner} />
        </Helmet>
        <div
          style={{ justifyContent: "center", alignItems: "center", flex: "1" }}
        >
          <p>Make sure you have Knowhere downloaded to view</p>
          <li>
            <a
              arget="_blank"
              rel="noopener noreferrer"
              href="https://apps.apple.com/us/app/knowhere/id6467689920"
            >
              <p style={{ color: "#603AE9" }}>Download Now for iOS</p>
            </a>
          </li>
        </div>
      </div>
    </div>
  );
};

export default Main;
