import { BrowserRouter as Router } from "react-router-dom";
import Routing from "./routes";
import "./assets/css/icofont.min.css";
import "./assets/css/owl.carousel.min.css";
import "./assets/css/bootstrap.min.css";
import "./assets/css/responsive.css";
import "./assets/css/style.css";
import ReactPixel from "react-facebook-pixel";

export default function App() {
  const options = {
    autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
    debug: false, // enable logs
  };
  ReactPixel.init("406115968621893", options);

  ReactPixel.pageView(); // For tracking page view

  return (
    <>
      <Router>
        <Routing />
      </Router>
    </>
  );
}
