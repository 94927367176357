import axiosClient from "../ApiClient";

export function attemptContact(data) {
  return axiosClient.post("/web/contact", {
    email: data.email,
    subject: data.subject,
    description: data.description,
  });
}

export function attemptSignUp(data) {
  return axiosClient.post("/companies", {
    email: data.email,
    name: data.companyName,
    password: data.password,
    address: {
      street1: data.street1,
      street2: data.street2,
      city: data.city,
      state: data.state,
      country: data.country,
      postalCode: data.zip,
    },
    phoneNumber: data.phone,
    website: data.website,
    isNonProfit: data.nonProfit,
    description: data.description,
  });
}
