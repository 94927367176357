import React from "react";
import BGImg from "../../assets/images/bread_crumb_bg.png";
import TermsOfService from "../../assets/js/terms_of_service";

const Main = () => {
  return (
    <>
      <div className="bred_crumb" style={{ backgroundImage: `url(${BGImg})` }}>
        <div className="container">
          <span className="banner_shape1">
            {" "}
            <img src="assets/images/banner-shape1.png" alt="" />
          </span>
          <span className="banner_shape2">
            {" "}
            <img src="assets/images/banner-shape2.png" alt="" />
          </span>
          <span className="banner_shape3">
            {" "}
            <img src="assets/images/banner-shape3.png" alt="" />
          </span>
          <div className="bred_text">
            <h1>Terms & Conditions</h1>
          </div>
        </div>
      </div>
      <div
        style={{
          paddingLeft: 20,
          paddingRight: 20,
          paddingTop: 100,
          paddingBottom: 100,
        }}
      >
        <TermsOfService />
      </div>
    </>
  );
};

export default Main;

<object
  data="/assets/pdf/privacy_policy.pdf"
  type="application/pdf"
  width="100%"
  height="100%"
>
  <text>OOPS</text>
</object>;
