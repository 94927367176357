import React from "react";
import BGImg from "../../assets/images/bread_crumb_bg.png";

const Main = () => {
  return (
    <>
      <div className="bred_crumb" style={{ backgroundImage: `url(${BGImg})` }}>
        <div className="container">
          <span className="banner_shape1">
            {" "}
            <img src="assets/images/banner-shape1.png" alt="" />
          </span>
          <span className="banner_shape2">
            {" "}
            <img src="assets/images/banner-shape2.png" alt="" />
          </span>
          <span className="banner_shape3">
            {" "}
            <img src="assets/images/banner-shape3.png" alt="" />
          </span>
          {/* Success */}
          <div className="bred_text">
            <h1>404</h1>
            <p>
              Uh Oh! Seems like this page does not exist. Maybe try something
              else?
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Main;
