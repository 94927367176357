import { Link } from "react-router-dom";
import React from "react";
import line from "../../assets/images/anim_line.png";
import blueapp from "../../assets/images/appstore_blue.png";
import blue from "../../assets/images/googleplay_blue.png";
import logo from "../../assets/images/footer_logo.png";
import top from "../../assets/images/go_top.png";

const Main = ({ footer }) => {
  return (
    <>
      {/* TODO Maybe Add Back
      <section className="newsletter_section">
        <div className="container">
          <div className="newsletter_box">
            <div
              className="section_title"
              data-aos="fade-in"
              data-aos-duration="1500"
              data-aos-delay="100"
            >
              <h2>Subscribe Newsletter</h2>
              <p>Be the first to recieve all latest posts in your inbox</p>
            </div>
            <form
              action=""
              data-aos="fade-in"
              data-aos-duration="1500"
              data-aos-delay="100"
            >
              <div className="form-group">
                <input
                  type="email"
                  className="form-control"
                  placeholder="Enter your email"
                />
              </div>
              <div className="form-group">
                <button className="btn">SUBMIT</button>
              </div>
            </form>
          </div>
        </div>
      </section>
  */}

      {footer.f1 && (
        <footer>
          <div className="top_footer" id="contact">
            {/* <div className="anim_line dark_bg">
              <span>
                <img src={line} alt="anim_line" />
              </span>
              <span>
                <img src={line} alt="anim_line" />
              </span>
              <span>
                <img src={line} alt="anim_line" />
              </span>
              <span>
                <img src={line} alt="anim_line" />
              </span>
              <span>
                <img src={line} alt="anim_line" />
              </span>
              <span>
                <img src={line} alt="anim_line" />
              </span>
              <span>
                <img src={line} alt="anim_line" />
              </span>
              <span>
                <img src={line} alt="anim_line" />
              </span>
              <span>
                <img src={line} alt="anim_line" />
              </span>
            </div> */}
            <div className="container">
              <div className="row">
                <div className="col-lg-4 col-md-6 col-12">
                  <div className="abt_side">
                    <div className="logo">
                      {" "}
                      <img src={logo} alt="" />
                    </div>
                    <ul>
                      <li>
                        <Link to="#">contact@knowheresocial.com</Link>
                      </li>
                    </ul>
                    <ul className="social_media">
                      <li>
                        <a
                          arget="_blank"
                          rel="noopener noreferrer"
                          href="https://www.linkedin.com/company/knowheresocial"
                        >
                          <i className="icofont-linkedin"></i>
                        </a>
                      </li>
                      <li>
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href="https://twitter.com/knowheresocial"
                        >
                          <i className="icofont-twitter"></i>
                        </a>
                      </li>
                      <li>
                        <a
                          arget="_blank"
                          rel="noopener noreferrer"
                          href="https://www.instagram.com/knowhere.social"
                        >
                          <i className="icofont-instagram"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-lg-2 col-md-6 col-12">
                  <div className="links">
                    <h3>Useful Links</h3>
                    <ul>
                      <li>
                        <Link to="/">Home</Link>
                      </li>
                      <li>
                        <Link to="/about">About us</Link>
                      </li>
                      <li>
                        <Link to="/signup">Sign Up</Link>
                      </li>
                      {/*
                      <li>
                        <Link to="/blog">Blog</Link>
                      </li>
                      */}
                      <li>
                        <Link to="/contact">Contact us</Link>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-lg-2 col-md-6 col-12">
                  <div className="links">
                    <h3>Help & Support</h3>
                    <ul>
                      <li>
                        <Link to="/faq">FAQs</Link>
                      </li>
                      <li>
                        <Link to="/contact">Support</Link>
                      </li>

                      <li>
                        <Link to="/terms">Terms & Conditions</Link>
                      </li>
                      <li>
                        <Link to="/privacy">Privacy Policy</Link>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 col-12">
                  <div className="links">
                    <h3>Resources</h3>
                    <ul>
                      <li>
                        <Link to="/ambassadors">Ambassador Program</Link>
                      </li>
                    </ul>
                  </div>
                </div>
                {/* TODO Add Back
                <div className="col-lg-2 col-md-6 col-12">
                  <div className="try_out">
                    <h3>Let’s Try It Out</h3>
                    <ul className="app_btn">
                      <li>
                        <Link to="#">
                          <img src={blueapp} alt="" />
                        </Link>
                      </li>
                      <li>
                        <Link to="#">
                          <img src={blue} alt="" />
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
      */}
              </div>
            </div>
          </div>
          <div className="bottom_footer">
            <div className="container">
              <div className="row">
                <div className="col-md-6">
                  <p>© Copyright 2024. All rights reserved.</p>
                </div>
              </div>
            </div>
          </div>
          <div className="go_top">
            <span>
              <img src={top} alt="" />
            </span>
          </div>
        </footer>
      )}
    </>
  );
};

export default Main;
