import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Bredcrumb from "../../Bredcrumb/Main";
import BGImg from "../../../assets/images/bread_crumb_bg.png";
import blueapp from "../../../assets/images/appstore_blue.png";
import downloadScreen1 from "../../../assets/images/download-screen01-new.png";
import downloadScreen2 from "../../../assets/images/download-screen02-new.png";

const Main = ({ brdcum }) => {
  const [activeFaq, setActiveFaq] = useState({ z: true });
  return (
    <>
      {brdcum.b1 && (
        <Bredcrumb
          no={1}
          title="Have questions? Look here"
          paragraph={`Our frequently asked questions are here to help!`}
          tag="FAQ"
          bgimg={BGImg}
        />
      )}

      <section className="row_am faq_section">
        <div className="container">
          <div className="faq_panel">
            <div className="accordion" id="accordionExample">
              <div className="card" data-aos="fade-up" data-aos-duration="1500">
                <div className="card-header" id="headingZero">
                  <h2 className="mb-0">
                    <button
                      type="button"
                      className={`btn btn-link ${activeFaq.z && "active"}`}
                      onClick={() =>
                        setActiveFaq(activeFaq.z ? { z: false } : { z: true })
                      }
                      data-toggle="collapse"
                      data-target="#collapseZero"
                    >
                      {activeFaq.z ? (
                        <i className="icon_faq icofont-minus"></i>
                      ) : (
                        <i className="icon_faq icofont-plus"></i>
                      )}{" "}
                      When will Knowhere be available ?
                    </button>
                  </h2>
                </div>
                <div
                  id="collapseZero"
                  className="collapse show"
                  aria-labelledby="headingZero"
                  data-parent="#accordionExample"
                >
                  <div className="card-body">
                    <p>
                      Knowhere is available <b>anywhere</b> in the United
                      States!
                      <br />
                      <br />
                      Users: Easily discover what is happening near you by
                      downloading the Knowhere app{" "}
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://apps.apple.com/us/app/knowhere/id6467689920"
                      >
                        here
                      </a>
                      <br />
                      <br />
                      Companies: Easily promote your experiences to your
                      customers by downloading the Knowhere Org app{" "}
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://apps.apple.com/us/app/knowhere/id6467689920"
                      >
                        here
                      </a>
                    </p>
                  </div>
                </div>
              </div>
              <div className="card" data-aos="fade-up" data-aos-duration="1500">
                <div className="card-header" id="headingFour">
                  <h2 className="mb-0">
                    <button
                      type="button"
                      className={`btn btn-link ${activeFaq.d && "active"}`}
                      onClick={() =>
                        setActiveFaq(activeFaq.d ? { d: false } : { d: true })
                      }
                      data-toggle="collapse"
                      data-target="#collapseFour"
                    >
                      {activeFaq.d ? (
                        <i className="icon_faq icofont-minus"></i>
                      ) : (
                        <i className="icon_faq icofont-plus"></i>
                      )}
                      Where is Knowhere available ?
                    </button>
                  </h2>
                </div>
                <div
                  id="collapseFour"
                  className="collapse"
                  aria-labelledby="headingFour"
                  data-parent="#accordionExample"
                >
                  <div className="card-body">
                    <p>
                      Knowhere is based in Boulder, CO and is also accessible
                      anywhere within the <b>United States</b>. Any business or
                      organization can join the platform{" "}
                      <Link to={"/signup"}>here</Link> or by downloading the
                      Knowhere Org app{" "}
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://apps.apple.com/us/app/knowhere/id6467689920"
                      >
                        here
                      </a>
                      . Users can see what is happening around them once
                      businesses in their area have joined the Knowhere
                      platform.
                    </p>
                  </div>
                </div>
              </div>
              <div className="card" data-aos="fade-up" data-aos-duration="1500">
                <div className="card-header" id="headingOne">
                  <h2 className="mb-0">
                    <button
                      type="button"
                      className={`btn btn-link ${activeFaq.a && "active"}`}
                      onClick={() =>
                        setActiveFaq(activeFaq.a ? { a: false } : { a: true })
                      }
                      data-toggle="collapse"
                      data-target="#collapseOne"
                    >
                      {activeFaq.a ? (
                        <i className="icon_faq icofont-minus"></i>
                      ) : (
                        <i className="icon_faq icofont-plus"></i>
                      )}{" "}
                      How can I sign up ?
                    </button>
                  </h2>
                </div>
                <div
                  id="collapseOne"
                  className="collapse"
                  aria-labelledby="headingOne"
                  data-parent="#accordionExample"
                >
                  <div className="card-body">
                    <p>
                      You can sign up in the following ways:
                      <br />
                      <br />
                      <b>Users:</b>
                      <br />
                      Users can join Knowhere immediately by downloading the
                      Knowhere app{" "}
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://apps.apple.com/us/app/knowhere/id6467689920"
                      >
                        here
                      </a>
                      ! <br /> <br />
                      <b>Companies:</b>
                      <br />
                      In order to maintain relevant and safe content, Knowhere
                      requires all companies to be approved through an
                      application process before having access to post
                      experiences. Once your application is submitted, our team
                      will make a decision and reach out to you within 48 hours.{" "}
                      You can get started online{" "}
                      <Link to={"/signup"}>here</Link> or by downloading the
                      Knowhere Org app{" "}
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://apps.apple.com/us/app/knowhere/id6467689920"
                      >
                        here.
                      </a>{" "}
                      You must verify your email address with the provided
                      email.
                    </p>
                  </div>
                </div>
              </div>
              <div className="card" data-aos="fade-up" data-aos-duration="1500">
                <div className="card-header" id="headingTwo">
                  <h2 className="mb-0">
                    <button
                      type="button"
                      className={`btn btn-link ${activeFaq.b && "active"}`}
                      onClick={() =>
                        setActiveFaq(activeFaq.b ? { b: false } : { b: true })
                      }
                      data-toggle="collapse"
                      data-target="#collapseTwo"
                    >
                      {activeFaq.b ? (
                        <i className="icon_faq icofont-minus"></i>
                      ) : (
                        <i className="icon_faq icofont-plus"></i>
                      )}{" "}
                      How much does it cost ?
                    </button>
                  </h2>
                </div>
                <div
                  id="collapseTwo"
                  className="collapse"
                  aria-labelledby="headingTwo"
                  data-parent="#accordionExample"
                >
                  <div className="card-body">
                    <p>
                      <b>Users</b>
                      <br />
                      Knowhere will always be free for users! <br /> <br />
                      <b>Companies:</b>
                      <br />
                      Knowhere will be free for a limited time in order to gain
                      businesses and grow the platform. In order to keep the
                      platform running, businesses will eventually have to
                      subscribe to a monthly subscription. We want this
                      transition to be as smooth as possible and are confident
                      we can do so with all parties happy. Our promise is that
                      we will be straightforward and honest when this time comes
                      and give you plenty of time to choose to stick around.
                      <br /> <br />
                      <b>Non-Profits:</b>
                      <br />
                      Knowhere will remain free for non-profit organizations!
                    </p>
                  </div>
                </div>
              </div>
              <div className="card" data-aos="fade-up" data-aos-duration="1500">
                <div className="card-header" id="headingThree">
                  <h2 className="mb-0">
                    <button
                      type="button"
                      className={`btn btn-link ${activeFaq.c && "active"}`}
                      onClick={() =>
                        setActiveFaq(activeFaq.c ? { c: false } : { c: true })
                      }
                      data-toggle="collapse"
                      data-target="#collapseThree"
                    >
                      {activeFaq.c ? (
                        <i className="icon_faq icofont-minus"></i>
                      ) : (
                        <i className="icon_faq icofont-plus"></i>
                      )}
                      What is an experience ?
                    </button>
                  </h2>
                </div>
                <div
                  id="collapseThree"
                  className="collapse"
                  aria-labelledby="headingThree"
                  data-parent="#accordionExample"
                >
                  <div className="card-body">
                    <p>
                      An experience is what businesses and organizations put on
                      display for users to see. These can range from events to
                      promotions, all with the shared purpose of bringing people
                      together - such as karaoke nights, comedy shows or happy
                      hours - rather than offering sales or discounts.
                    </p>
                  </div>
                </div>
              </div>

              <div className="card" data-aos="fade-up" data-aos-duration="1500">
                <div className="card-header" id="headingFive">
                  <h2 className="mb-0">
                    <button
                      type="button"
                      className={`btn btn-link ${activeFaq.e && "active"}`}
                      onClick={() =>
                        setActiveFaq(activeFaq.e ? { e: false } : { e: true })
                      }
                      data-toggle="collapse"
                      data-target="#collapseFive"
                    >
                      {activeFaq.e ? (
                        <i className="icon_faq icofont-minus"></i>
                      ) : (
                        <i className="icon_faq icofont-plus"></i>
                      )}
                      How can I get help ?
                    </button>
                  </h2>
                </div>
                <div
                  id="collapseFive"
                  className="collapse"
                  aria-labelledby="headingFive"
                  data-parent="#accordionExample"
                >
                  <div className="card-body">
                    <p>
                      Anyone can get support by emailing us directly at{" "}
                      <b>support@knowheresocial.com</b>. Or by going to our{" "}
                      <Link to={"/contact"}>contact page</Link>.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        className="row_am free_app_section review_freeapp"
        id="getstarted"
        style={{ zIndex: "0" }}
      >
        <div className="container">
          <div
            className="free_app_inner aos-init"
            data-aos="fade-in"
            data-aos-duration="1500"
            data-aos-delay="100"
          >
            <div className="anim_line dark_bg">
              <span>
                <img src="assets/images/anim_line.png" alt="anim_line" />
              </span>
              <span>
                <img src="assets/images/anim_line.png" alt="anim_line" />
              </span>
              <span>
                <img src="assets/images/anim_line.png" alt="anim_line" />
              </span>
              <span>
                <img src="assets/images/anim_line.png" alt="anim_line" />
              </span>
              <span>
                <img src="assets/images/anim_line.png" alt="anim_line" />
              </span>
              <span>
                <img src="assets/images/anim_line.png" alt="anim_line" />
              </span>
              <span>
                <img src="assets/images/anim_line.png" alt="anim_line" />
              </span>
              <span>
                <img src="assets/images/anim_line.png" alt="anim_line" />
              </span>
              <span>
                <img src="assets/images/anim_line.png" alt="anim_line" />
              </span>
            </div>
            <div style={{ zIndex: 1 }} className="row">
              <div className="col-md-6">
                <div className="free_text">
                  <div className="section_title">
                    <h2>Download for free</h2>
                    <p>
                      Get started in just a few clicks! Available for free on
                      iOS.
                    </p>
                  </div>
                  <div>
                    <p style={{ color: "white" }}>For users:</p>
                  </div>
                  <ul className="app_btn">
                    <li>
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://apps.apple.com/us/app/knowhere/id6467689920"
                      >
                        <img src={blueapp} alt="" />
                      </a>
                    </li>
                  </ul>
                  <div style={{ marginTop: 15 }}>
                    <p style={{ color: "white" }}>For companies:</p>
                  </div>
                  <ul className="app_btn">
                    <li>
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href=" https://apps.apple.com/us/app/knowhere-org/id6467690495"
                      >
                        <img src={blueapp} alt="" />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-md-6">
                <div className="free_img">
                  <img src={downloadScreen1} alt="image" />
                  <img
                    className="mobile_mockup"
                    src={downloadScreen2}
                    alt="image"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="row_am query_section">
        <div
          className="query_inner"
          data-aos="fade-in"
          data-aos-duration="1500"
        >
          <div className="container">
            <span className="banner_shape1">
              {" "}
              <img src="assets/images/banner-shape1.png" alt="image" />{" "}
            </span>
            <span className="banner_shape2">
              {" "}
              <img src="assets/images/banner-shape2.png" alt="image" />{" "}
            </span>
            <span className="banner_shape3">
              {" "}
              <img src="assets/images/banner-shape3.png" alt="image" />{" "}
            </span>

            <div className="section_title">
              <h2>Have a question ?</h2>
              <p>
                Feel free to reach out to contact@knowheresocial.com <br /> with
                any questions you may have!
              </p>
            </div>
            <Link to="/contact" className="btn white_btn">
              CONTACT US NOW
            </Link>
          </div>
        </div>
      </section>
    </>
  );
};

export default Main;
