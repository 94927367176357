import axiosClient from "../ApiClient";

export function confirmPasswordResetToken(token) {
  return axiosClient.get("/web/passwordResetConfirm", {
    params: {
      token: token,
    },
  });
}

export function setNewPassword(data) {
  return axiosClient.post(
    "/web/newPassword",
    {
      updatedPassword: data.password,
    },
    {
      params: {
        token: data.token,
      },
    }
  );
}
