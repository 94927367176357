export const inputTypes = {
  MULTILINE: "multiline",
  TEXTBOX: "textbox",
  CHECKMARK: "checkmark",
  DROPDOWN: "dropdown",
};

export const email_validation = {
  name: "email",
  label: "email",
  type: "email",
  id: "email",
  placeholder: "Email",
  inputType: inputTypes.TEXTBOX,
  validation: {
    required: {
      value: true,
      message: "required",
    },
    pattern: {
      value:
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      message: "not valid",
    },
  },
};

export const street1_validation = {
  name: "street1",
  label: "street1",
  type: "text",
  id: "street1",
  placeholder: "Street 1",
  inputType: inputTypes.TEXTBOX,
  validation: {
    required: {
      value: true,
      message: "required",
    },
  },
};

export const street2_validation = {
  name: "street2",
  label: "street2",
  type: "text",
  id: "street2",
  placeholder: "Street 2",
  inputType: inputTypes.TEXTBOX,
  validation: {
    required: {
      value: false,
    },
  },
};

export const city_validation = {
  name: "city",
  label: "city",
  type: "text",
  id: "city",
  placeholder: "City",
  inputType: inputTypes.TEXTBOX,
  validation: {
    required: {
      value: true,
      message: "required",
    },
  },
};

export const state_validation = {
  name: "state",
  label: "state",
  type: "dropdown",
  id: "state",
  placeholder: "State",
  inputType: inputTypes.DROPDOWN,
  validation: {
    required: {
      value: true,
      message: "required",
    },
  },
};

export const country_validation = {
  name: "country",
  label: "country",
  type: "text",
  id: "country",
  placeholder: "Country",
  inputType: inputTypes.TEXTBOX,
  validation: {
    required: {
      value: true,
      message: "required",
    },
  },
};

export const zip_validation = {
  name: "zip",
  label: "zip",
  type: "text",
  id: "zip",
  placeholder: "Postal Code",
  inputType: inputTypes.TEXTBOX,
  validation: {
    required: {
      value: true,
      message: "required",
    },
  },
};

export const phone_validation = {
  name: "phone",
  label: "phone",
  type: "tel",
  id: "phone",
  placeholder: "Phone",
  inputType: inputTypes.TEXTBOX,
  validation: {
    required: {
      value: true,
      message: "required",
    },
    pattern: {
      value: /^(\+\d{1,2}\s?)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/,
      message: "not valid",
    },
  },
};

export const website_validation = {
  name: "website",
  label: "website",
  type: "text",
  id: "website",
  placeholder: "Website",
  inputType: inputTypes.TEXTBOX,
  validation: {
    required: {
      value: true,
      message: "required",
    },
    pattern: {
      value:
        /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/,
      message: "not valid",
    },
  },
};

export const subject_validation = {
  name: "subject",
  label: "subject",
  type: "text",
  id: "subject",
  placeholder: "Subject",
  inputType: inputTypes.TEXTBOX,
  validation: {
    required: {
      value: true,
      message: "required",
    },
  },
};

export const companyName_validation = {
  name: "companyName",
  label: "companyName",
  type: "text",
  id: "companyName",
  placeholder: "Company Name",
  inputType: inputTypes.TEXTBOX,
  validation: {
    required: {
      value: true,
      message: "required",
    },
  },
};

export const description_validation = {
  name: "description",
  label: "description",
  type: "text",
  id: "description",
  placeholder: "Whats up?",
  inputType: inputTypes.MULTILINE,
  validation: {
    required: {
      value: true,
      message: "required",
    },
  },
};

export const password_check_validation = {
  name: "passwordCheck",
  label: "passwordCheck",
  type: "password",
  id: "passwordCheck",
  placeholder: "Confirm Password",
  inputType: inputTypes.TEXTBOX,
  validation: {
    required: {
      value: true,
      message: "required",
    },
    minLength: {
      value: 7,
      message: "min 7 characters",
    },
  },
};

export const password_validation = {
  name: "password",
  label: "password",
  type: "password",
  id: "password",
  placeholder: "Password",
  inputType: inputTypes.TEXTBOX,
  validation: {
    required: {
      value: true,
      message: "required",
    },
    minLength: {
      value: 7,
      message: "min 7 characters",
    },
  },
};

export const nonProfit_validation = {
  name: "nonProfit",
  label: "nonProfit",
  type: "checkbox",
  id: "nonProfit",
  inputType: inputTypes.CHECKMARK,
  validation: {
    required: {
      value: false,
    },
  },
};
