import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import BGImg from "../../assets/images/bread_crumb_bg.png";
import {
  confirmPasswordResetToken,
  setNewPassword,
} from "../../network/lib/auth";
import { FormProvider, useForm } from "react-hook-form";
import {
  password_check_validation,
  password_validation,
} from "../../util/formValidations";
import { Input } from "../Form/Input";

const states = {
  LOADING: "LOADING",
  ERROR: "ERROR",
  SUCCESS: "SUCCESS",
  UPDATE_PASSWORD: "UPDATE_PASSWORD",
};

const Main = () => {
  const methods = useForm();
  const [searchParams, setSearchParams] = useSearchParams();
  const [token, setToken] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isSamePassword, setIsSamePassword] = useState(null);
  const [verificationState, setVerificationState] = useState(null);
  const [failureMessage, setFailureMessage] = useState(
    "An error occured when resetting your password. Please try again later"
  );

  const onSubmit = methods.handleSubmit((data) => {
    if (data.password === data.passwordCheck) {
      setIsSamePassword(true);
    } else {
      setIsSamePassword(false);
      return;
    }
    setIsLoading(true);
    const request = {
      token: token,
      password: data.password,
    };
    setNewPassword(request)
      .then((response) => {
        setVerificationState(states.SUCCESS);
      })
      .catch((error) => {
        if (error.code === "ERR_NETWORK") {
          setFailureMessage(
            "An issue occured while processing your request, please try again later."
          );
        } else {
          if (error.response.status === 404) {
            setFailureMessage(
              "Your password reset email is invalid. Please request a new one within the app."
            );
          } else if (error.response.status === 400) {
            setFailureMessage(
              "Your password reset email has expired. Please request a new one within the app."
            );
          }
        }
        setVerificationState(states.ERROR);
      });
  });

  useEffect(() => {
    const handleToken = () => {
      setVerificationState(states.LOADING);
      if (token === null) {
        setFailureMessage("You must request a password reset within the app.");
        setVerificationState(states.ERROR);
      } else {
        confirmPasswordResetToken(token)
          .then(() => {
            setVerificationState(states.UPDATE_PASSWORD);
          })
          .catch((error) => {
            if (error.code === "ERR_NETWORK") {
              setFailureMessage(
                "An issue occured while processing your request, please try again later."
              );
            } else {
              if (error.response.status === 404) {
                setFailureMessage(
                  "Your password reset email is invalid. Please request a new one within the app."
                );
              } else if (error.response.status === 400) {
                setFailureMessage(
                  "Your password reset email has expired. Please request a new one within the app."
                );
              }
            }
            setVerificationState(states.ERROR);
          });
      }
    };

    setToken(searchParams.get("token"));
    handleToken();
  }, [searchParams, token]);

  return (
    <>
      <div className="bred_crumb" style={{ backgroundImage: `url(${BGImg})` }}>
        <div className="container">
          <span className="banner_shape1">
            {" "}
            <img src="assets/images/banner-shape1.png" alt="" />
          </span>
          <span className="banner_shape2">
            {" "}
            <img src="assets/images/banner-shape2.png" alt="" />
          </span>
          <span className="banner_shape3">
            {" "}
            <img src="assets/images/banner-shape3.png" alt="" />
          </span>
          {/* Success */}
          {verificationState === states.SUCCESS && (
            <div className="bred_text">
              <h1>Success!</h1>
              <p>You have succesfully updated your password!</p>
            </div>
          )}
          {/* Failure */}
          {verificationState === states.ERROR && (
            <div className="bred_text">
              <h1>Uh Oh!</h1>
              <p>{failureMessage}</p>
            </div>
          )}
          {/* Update Password */}
          {verificationState === states.UPDATE_PASSWORD && (
            <section className="password_reset_section" id="reset">
              <div className="container">
                <div className="password_reset_inner">
                  <div className="reset_form">
                    <div className="section_title">
                      <h2>
                        Lets get your password <span>Reset</span>
                      </h2>
                      <p>Create a new password</p>
                    </div>
                    <FormProvider {...methods}>
                      <form onSubmit={(e) => e.preventDefault} noValidate>
                        <div className="form-group">
                          <Input {...password_validation} />
                        </div>
                        <div className="form-group">
                          <Input {...password_check_validation} />
                        </div>
                        {isSamePassword === false && (
                          <div>
                            <text>Passwords Do Not Match!</text>
                          </div>
                        )}
                        <button
                          onClick={onSubmit}
                          disabled={isLoading}
                          className="btn puprple_btn"
                        >
                          {isLoading ? (
                            <span class="loader"></span>
                          ) : (
                            <> Reset Password</>
                          )}
                        </button>
                      </form>
                    </FormProvider>
                  </div>
                </div>
              </div>
            </section>
          )}
          {/* Loading */}
          {verificationState === states.LOADING && (
            <div className="bred_text">
              <h1>Verifying Request</h1>
              <p>Hang Tight!</p>
              <span class="loader"></span>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Main;
