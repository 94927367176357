import { Link } from "react-router-dom";
import React from "react";
import frame from "../../../assets/images/mobile_frame_svg.svg";
import screen from "../../../assets/images/about-screen.png";
import img1 from "../../../assets/images/download.png";
import img2 from "../../../assets/images/followers.png";
import img3 from "../../../assets/images/reviews.png";
import img4 from "../../../assets/images/countries.png";
import organization from "../../../assets/images/screens/Organization.jpg";
import createEvent from "../../../assets/images/screens/Create Event.png";
import dashboard from "../../../assets/images/screens/Dashbaord Screen.png";

const Main = ({ video, dark }) => {
  return (
    <>
      <section className="row_am about_app_section">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div
                className="about_img"
                data-aos="fade-in"
                data-aos-duration="1500"
              >
                <div className="frame_img">
                  <img
                    className="moving_position_animatin"
                    src={organization}
                    width={320}
                    alt=""
                  />
                </div>
                <div className="slider_frame">
                  <img src={frame} alt="" />
                </div>
                <div className="screen_img">
                  <img
                    className="moving_animation"
                    src={createEvent}
                    alt=""
                    width={270}
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="about_text">
                <div
                  className="section_title"
                  data-aos="fade-up"
                  data-aos-duration="1500"
                  data-aos-delay="100"
                >
                  <h2>
                    Advertise directly to your <span>Customers.</span>
                  </h2>
                  <p>
                    Get new customers to discover you by advertising your
                    current or future events directly to them. No more large SEO
                    and ad marketing budgets.
                  </p>
                </div>
                <img className="" src={dashboard} alt="" width={540} />
                <div style={{ marginTop: "25px" }}>
                  <p>
                    Stay connected to customers and promote events directly to
                    your community from any device!
                  </p>
                  <Link
                    to="/signup"
                    className="btn puprple_btn"
                    data-aos="fade-in"
                    data-aos-duration="1500"
                  >
                    START FOR FREE
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Main;
