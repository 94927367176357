import { HashLink as Link } from "react-router-hash-link";
import React, { useEffect } from "react";
import AOS from "aos";
import OwlCarousel from "react-owl-carousel";
import Trusted from "../HomeMain/Trusted/Main";
import Features from "../HomeMain/Features/Main";
import AboutApp from "../HomeMain/AboutApp/Main";
import Design from "../HomeMain/Design/Main";
import Work from "../HomeMain/Work/Main";
import Faq from "../HomeMain/Faq/Main";
import Interface from "../HomeMain/Interface/Main";
import Download from "../HomeMain/Download/Main";
import Story from "../HomeMain/Story/Main";

import blueapp from "../../assets/images/appstore_blue.png";
import whiteapp from "../../assets/images/appstore_white.png";
import blue from "../../assets/images/googleplay_blue.png";
import white from "../../assets/images/googleplay_white.png";
import used1 from "../../assets/images/used01.png";
import used2 from "../../assets/images/used02.png";
import used3 from "../../assets/images/used03.png";
import used4 from "../../assets/images/used04.png";
import frame from "../../assets/images/mobile_frame_svg.svg";
import social from "../../assets/images/screens/Social Carousel.jpg";
import splashScreen from "../../assets/images/screens/Splash Screen.png";
import discover from "../../assets/images/screens/Discover Carousel.jpg";
import event from "../../assets/images/screens/Event Screen.jpg";

const Main = ({ setfooter, setnavbar, setbrdcum }) => {
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);

  useEffect(() => {
    setfooter({ f1: true });
    setnavbar({ n2: true });
    setbrdcum({ b1: true });
    localStorage.setItem("navbar", "home");
  }, [setfooter, setnavbar, setbrdcum]);

  const frame_slider = {
    loop: true,
    margin: 10,
    nav: false,
    autoplay: true,
    smartSpeed: 1500,
    dots: true,
    responsive: {
      0: {
        items: 1,
      },
      600: {
        items: 1,
      },
      1000: {
        items: 1,
      },
    },
  };

  return (
    <>
      <section className="banner_section home-banner">
        <div className="container">
          <div className="row">
            <div
              className="col-lg-6 col-md-12"
              data-aos="fade-right"
              data-aos-duration="1500"
            >
              <div className="banner_text">
                <h1>
                  The best way to <br />
                  <span>Be in the Know.</span>
                </h1>
                <p>
                  Easily discover what you and your friends want to do, all in
                  your hand, all in one place.
                </p>
              </div>
              {/* TODO Add Back
              <ul className="app_btn">
                <li>
                  <Link to="#">
                    <img className="blue_img" src={blueapp} alt="" />
                    <img className="white_img" src={whiteapp} alt="" />
                  </Link>
                </li>
                <li>
                  <Link to="#">
                    <img className="blue_img" src={blue} alt="" />
                    <img className="white_img" src={white} alt="" />
                  </Link>
                </li>
              </ul>

              <div className="used_app">
                <ul>
                  <li>
                    <img src={used1} alt="" />
                  </li>
                  <li>
                    <img src={used2} alt="" />
                  </li>
                  <li>
                    <img src={used3} alt="" />
                  </li>
                  <li>
                    <img src={used4} alt="" />
                  </li>
                </ul>
                <p>uses this app</p>
              </div>
            */}
            </div>
            <div
              className="col-lg-6 col-md-12"
              data-aos="fade-in"
              data-aos-duration="1500"
            >
              <div className="banner_slider">
                <OwlCarousel
                  id="frame_slider"
                  className="owl-carousel owl-theme owl-loaded owl-drag"
                  {...frame_slider}
                  margin={0}
                >
                  <div className="item">
                    <div className="slider_img">
                      <img src={discover} alt="" />
                    </div>
                  </div>
                  <div className="item">
                    <div className="slider_img">
                      <img src={event} alt="" />
                    </div>
                  </div>
                  <div className="item">
                    <div className="slider_img">
                      <img src={social} alt="" />
                    </div>
                  </div>
                  <div className="item">
                    <div className="slider_img">
                      <img src={splashScreen} alt="" />
                    </div>
                  </div>
                </OwlCarousel>
                <div className="slider_frame">
                  <img src={frame} alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* TODO Add Back <Trusted /> */}
      <Download />

      <Features />
      <>
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <section className="row_am trusted_section">
          <div className="container">
            <div
              className="section_title"
              data-aos="fade-up"
              data-aos-duration="1500"
              data-aos-delay="100"
            >
              <h2>
                Perfect for any <span>Business</span>
              </h2>
              <p>Get ready to lower your marketing budget.</p>
            </div>
          </div>
        </section>
      </>
      <AboutApp />

      {/* <Design />
      TODO Add Back
      <Work /> */}

      <Interface />

      <Faq />

      {/* <Story /> */}
    </>
  );
};

export default Main;
