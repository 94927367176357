import { Link } from "react-router-dom";
import React, { useState } from "react";
import img from "../../../assets/images/anim_line.png";
import blueapp from "../../../assets/images/appstore_blue.png";
import blue from "../../../assets/images/googleplay_blue.png";
import downloadScreen1 from "../../../assets/images/download-screen01-new.png";
import downloadScreen2 from "../../../assets/images/download-screen02-new.png";
import social from "../../../assets/images/screens/Social Carousel.jpg";
import ReactPixel from "react-facebook-pixel";

const Main = () => {
  const [animate, setanimate] = useState();

  window.addEventListener("scroll", function () {
    const element = document.getElementsByClassName("free_text");
    const position = element[0].getBoundingClientRect();

    if (position.top < window.innerHeight && position.bottom >= 0) {
      const elm = document.getElementsByClassName("purple_backdrop");
      elm[0].style.opacity = "1";
    } else {
      const elm = document.getElementsByClassName("purple_backdrop");
      elm[0].style.opacity = "0";
    }
  });

  const userDownloadClicked = () => {
    ReactPixel.track("ViewContent", {
      content_name: "User Download",
      content_category: "Download",
      content_type: "product",
    });
  };

  const businessDownloadClicked = () => {
    ReactPixel.track("ViewContent", {
      content_name: "Business Download",
      content_category: "Download",
      content_type: "product",
    });
  };

  return (
    <>
      <section className="row_am free_app_section review_freeapp" id="download">
        <div className="container">
          <div
            className={`free_app_inner aos-init ${animate && "aos-animate"}`}
            data-aos="fade-in"
            data-aos-duration="1500"
            data-aos-delay="100"
          >
            <div className="anim_line dark_bg">
              <span>
                <img src={img} alt="anim_line" />
              </span>
              <span>
                <img src={img} alt="anim_line" />
              </span>
              <span>
                <img src={img} alt="anim_line" />
              </span>
              <span>
                <img src={img} alt="anim_line" />
              </span>
              <span>
                <img src={img} alt="anim_line" />
              </span>
              <span>
                <img src={img} alt="anim_line" />
              </span>
              <span>
                <img src={img} alt="anim_line" />
              </span>
              <span>
                <img src={img} alt="anim_line" />
              </span>
              <span>
                <img src={img} alt="anim_line" />
              </span>
            </div>
            <div className="row">
              <div className="col-md-6">
                <div className="free_text">
                  <div className="section_title">
                    <h2>Download for free</h2>
                    <p>
                      Get started in just a few clicks! Available for free on
                      iOS.
                    </p>
                  </div>
                  <div>
                    <p style={{ color: "white" }}>For users:</p>
                  </div>
                  <ul className="app_btn">
                    <li>
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://apps.apple.com/us/app/knowhere/id6467689920"
                        onClick={userDownloadClicked}
                      >
                        <img src={blueapp} alt="" />
                      </a>
                    </li>
                  </ul>
                  <div style={{ marginTop: 15 }}>
                    <p style={{ color: "white" }}>For companies:</p>
                  </div>
                  <ul className="app_btn">
                    <li>
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href=" https://apps.apple.com/us/app/knowhere-org/id6467690495"
                        id="business_download"
                        onClick={businessDownloadClicked}
                      >
                        <img src={blueapp} alt="" />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-md-6">
                <div className="free_img">
                  <img src={downloadScreen1} alt="" />
                  <img className="mobile_mockup" src={downloadScreen2} alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="purple_backdrop"></div>
    </>
  );
};

export default Main;
