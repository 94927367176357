import React, { useState, useEffect } from "react";
import Trusted from "../HomeMain/Trusted/Main";
import { Link } from "react-router-dom";
import Bredcrumb from "../Bredcrumb/Main";
import BGImg from "../../assets/images/bread_crumb_bg.png";
import BGImg1 from "../../assets/images/bread_crumb_bg_one.png";
import BGImg2 from "../../assets/images/bread_crumb_bg_two.png";
import about1 from "../../assets/images/ambassador_1.png";
import about2 from "../../assets/images/ambassador_2.png";
import about3 from "../../assets/images/ambassador_3.png";
import { FormProvider, useForm } from "react-hook-form";
import { Input } from "../Form/Input";
import { attemptContact } from "../../network/lib/forms";
import {
  description_validation,
  email_validation,
  subject_validation,
} from "../../util/formValidations";
import { BsFillCheckCircleFill, BsFillXCircleFill } from "react-icons/bs";

const Main = ({ brdcum }) => {
  const methods = useForm();
  const [success, setSuccess] = useState(false);
  const [failure, setFailure] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const sendQuestion = async (data) => {
    data.subject = "AMBASSADOR APPLICATION";
    attemptContact(data)
      .then(() => {
        setIsLoading(false);
        setSuccess(true);
        methods.reset();
      })
      .catch((error) => {
        setIsLoading(false);
        setFailure(true);
      });
  };

  const onSubmit = methods.handleSubmit((data) => {
    setIsLoading(true);
    setSuccess(false);
    setFailure(false);
    sendQuestion(data);
  });
  return (
    <>
      <Bredcrumb
        no={1}
        title="Ambassador Program"
        tag="Ambassador"
        bgimg={BGImg}
      />

      <section className="row_am app_solution_section">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="app_text">
                <div
                  className="section_title"
                  data-aos="fade-up"
                  data-aos-duration="1500"
                  data-aos-delay="100"
                >
                  <h2>
                    <span>Ambassador</span> Program.
                  </h2>
                </div>
                <p data-aos="fade-up" data-aos-duration="1500">
                  Join our ambassador program and help our mission of empowering
                  businesses, connecting communities, and transforming local
                  engagement.
                </p>
                <p data-aos="fade-up" data-aos-duration="1500">
                  Our flexible ambassador program allows you to earn money on
                  the side. We offer the ability for you to get paid on a
                  per-user basis as well as flat payouts for social media
                  posts.
                </p>
              </div>
            </div>
            <div className="col-lg-6">
              <div
                className="app_images"
                data-aos="fade-in"
                data-aos-duration="1500"
              >
                <ul>
                  <li>
                    <img src={about1} alt="" width={400} />
                  </li>
                  <li>
                    <img src={about2} alt="" width={220} />
                  </li>
                  <li>
                    <img src={about3} alt="" width={320} />
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="contact_page_section" id="contact">
        <div className="container">
          <div className="contact_inner">
            <div className="contact_form">
              <div className="section_title">
                <h2>
                  Want to become an <span>Ambassador?</span>
                </h2>
                <p>
                  Send us a message with your social media handles and we will
                  reach out with more information!
                </p>
              </div>
              <FormProvider {...methods}>
                <form onSubmit={(e) => e.preventDefault()} noValidate>
                  <div className="form-group">
                    <Input {...email_validation} />
                  </div>
                  <div className="form-group">
                    <Input
                      {...description_validation}
                      placeholder="Social Media handles and anything else"
                    />
                  </div>

                  {success && (
                    <div className="success">
                      <p>
                        <BsFillCheckCircleFill /> Ambassador request has been
                        submitted succesfully
                      </p>
                    </div>
                  )}
                  {failure && (
                    <div className="failure">
                      <p>
                        <BsFillXCircleFill /> A problem occured. Please try
                        again.
                      </p>
                    </div>
                  )}
                  <div className="form-group mb-0">
                    <button
                      disabled={isLoading}
                      onClick={onSubmit}
                      className="btn puprple_btn"
                    >
                      {isLoading ? (
                        <span class="loader"></span>
                      ) : (
                        <> SEND MESSAGE</>
                      )}
                    </button>
                  </div>
                </form>
              </FormProvider>
            </div>
          </div>
        </div>
      </section>
      {/* TODO Add Back Maybe 
      <section className="row_am about_app_section about_page_sectino">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div
                className="abt_img"
                data-aos="fade-in"
                data-aos-duration="1500"
              >
                <img src="assets/images/about_main.png" alt="image" />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="about_text">
                <div
                  className="section_title"
                  data-aos="fade-up"
                  data-aos-duration="1500"
                  data-aos-delay="100"
                >
                  <h2>
                    {" "}
                    <span>We focus on quality,</span> never focus on quantity
                  </h2>
                  <p>
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry lorem Ipsum has been the industrys
                    standard dummy text ever since the when an unknown printer
                    took a galley of type and.
                  </p>
                </div>
                <ul
                  className="app_statstic"
                  id="counter"
                  data-aos="fade-in"
                  data-aos-duration="1500"
                >
                  <li>
                    <div className="icon">
                      <img src="assets/images/download.png" alt="image" />
                    </div>
                    <div className="text">
                      <p>
                        <span className="counter-value" data-count="17">
                          0
                        </span>
                        <span>M+</span>
                      </p>
                      <p>Download</p>
                    </div>
                  </li>
                  <li>
                    <div className="icon">
                      <img src="assets/images/followers.png" alt="image" />
                    </div>
                    <div className="text">
                      <p>
                        <span className="counter-value" data-count="08">
                          0{" "}
                        </span>
                        <span>M+</span>
                      </p>
                      <p>Followers</p>
                    </div>
                  </li>
                  <li>
                    <div className="icon">
                      <img src="assets/images/reviews.png" alt="image" />
                    </div>
                    <div className="text">
                      <p>
                        <span className="counter-value" data-count="2300">
                          1500
                        </span>
                        <span>+</span>
                      </p>
                      <p>Reviews</p>
                    </div>
                  </li>
                  <li>
                    <div className="icon">
                      <img src="assets/images/countries.png" alt="image" />
                    </div>
                    <div className="text">
                      <p>
                        <span className="counter-value" data-count="150">
                          0
                        </span>
                        <span>+</span>
                      </p>
                      <p>Countries</p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
  */}
      {/* TODO Add Back 
      <section className="row_am experts_team_section">
        <div className="container">
          <div
            className="section_title"
            data-aos="fade-up"
            data-aos-duration="1500"
            data-aos-delay="100"
          >
            <h2>
              {" "}
              Meet the <span> team </span>
            </h2>
            <p>
              Lorem Ipsum is simply dummy text of the printing and typese tting{" "}
              <br /> indus orem Ipsum has beenthe standard dummy.
            </p>
          </div>
          <div className="row">
            <div
              className="col-md-6 col-lg-3"
              data-aos="fade-up"
              data-aos-duration="1500"
              data-aos-delay="100"
            >
              <div className="experts_box">
                <img src="assets/images/experts_01.png" alt="image" />
                <div className="text">
                  <h3>Steav Joe</h3>
                  <span>CEO & Co-Founder</span>
                  <ul className="social_media">
                    <li>
                      <Link to="#">
                        <i className="icofont-facebook"></i>
                      </Link>
                    </li>
                    <li>
                      <Link to="#">
                        <i className="icofont-twitter"></i>
                      </Link>
                    </li>
                    <li>
                      <Link to="#">
                        <i className="icofont-instagram"></i>
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div
              className="col-md-6 col-lg-3"
              data-aos="fade-up"
              data-aos-duration="1500"
              data-aos-delay="200"
            >
              <div className="experts_box">
                <img src="assets/images/experts_02.png" alt="image" />
                <div className="text">
                  <h3>Mark Dele</h3>
                  <span>Co-Founder</span>
                  <ul className="social_media">
                    <li>
                      <Link to="#">
                        <i className="icofont-facebook"></i>
                      </Link>
                    </li>
                    <li>
                      <Link to="#">
                        <i className="icofont-twitter"></i>
                      </Link>
                    </li>
                    <li>
                      <Link to="#">
                        <i className="icofont-instagram"></i>
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div
              className="col-md-6 col-lg-3"
              data-aos="fade-up"
              data-aos-duration="1500"
              data-aos-delay="300"
            >
              <div className="experts_box">
                <img src="assets/images/experts_03.png" alt="image" />
                <div className="text">
                  <h3>Jolley Sihe</h3>
                  <span>Business Developer</span>
                  <ul className="social_media">
                    <li>
                      <Link to="#">
                        <i className="icofont-facebook"></i>
                      </Link>
                    </li>
                    <li>
                      <Link to="#">
                        <i className="icofont-twitter"></i>
                      </Link>
                    </li>
                    <li>
                      <Link to="#">
                        <i className="icofont-instagram"></i>
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div
              className="col-md-6 col-lg-3"
              data-aos="fade-up"
              data-aos-duration="1500"
              data-aos-delay="400"
            >
              <div className="experts_box">
                <img src="assets/images/experts_04.png" alt="image" />
                <div className="text">
                  <h3>Rimy Nail</h3>
                  <span>Marketing & Sales</span>
                  <ul className="social_media">
                    <li>
                      <Link to="#">
                        <i className="icofont-facebook"></i>
                      </Link>
                    </li>
                    <li>
                      <Link to="#">
                        <i className="icofont-twitter"></i>
                      </Link>
                    </li>
                    <li>
                      <Link to="#">
                        <i className="icofont-instagram"></i>
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
*/}
      <section className="row_am query_section">
        <div
          className="query_inner"
          data-aos="fade-in"
          data-aos-duration="1500"
        >
          <div className="container">
            <span className="banner_shape1">
              {" "}
              <img src="assets/images/banner-shape1.png" alt="image" />{" "}
            </span>
            <span className="banner_shape2">
              {" "}
              <img src="assets/images/banner-shape2.png" alt="image" />{" "}
            </span>
            <span className="banner_shape3">
              {" "}
              <img src="assets/images/banner-shape3.png" alt="image" />{" "}
            </span>

            <div className="section_title">
              <h2>Have a question ?</h2>
              <p>
                Feel free to reach out to contact@knowheresocial.com <br /> with
                any questions you may have!
              </p>
            </div>
            <Link to="/contact" className="btn white_btn">
              CONTACT US NOW
            </Link>
          </div>
        </div>
      </section>
    </>
  );
};

export default Main;
