import React from "react";
import { useFormContext } from "react-hook-form";
import { AnimatePresence, motion } from "framer-motion";
import { MdError } from "react-icons/md";
import { findInputError } from "../../util/findInputError";
import { isFormInvalid } from "../../util/isFormInvalid";
import { inputTypes } from "../../util/formValidations";

export const Input = ({
  label,
  type,
  id,
  placeholder,
  validation,
  name,
  inputType,
}) => {
  const {
    register,
    formState: { errors },
  } = useFormContext();

  const inputError = findInputError(errors, label);
  const isInvalid = isFormInvalid(inputError);

  return (
    <div>
      {inputType === inputTypes.MULTILINE && (
        <textarea
          className="form-control"
          placeholder={placeholder}
          {...register(`${name}`, validation)}
        />
      )}
      {inputType === inputTypes.TEXTBOX && (
        <input
          id={id}
          type={type}
          className="form-control"
          placeholder={placeholder}
          {...register(`${name}`, validation)}
        />
      )}
      {inputType === inputTypes.CHECKMARK && (
        <div className="form-group term_check">
          <input id={id} type={type} {...register(`${name}`, validation)} />
          <label htmlFor="nonProfit">I am a non-profit</label>
        </div>
      )}

      <AnimatePresence mode="wait" initial={false}>
        {isInvalid && (
          <InputError
            message={inputError.error.message}
            key={inputError.error.message}
          />
        )}
      </AnimatePresence>
    </div>
  );
};

const InputError = ({ message }) => {
  return (
    <motion.p className="error-control" {...framer_error}>
      <MdError />
      {message}
    </motion.p>
  );
};

const framer_error = {
  initial: { opacity: 0, y: 10 },
  animate: { opacity: 1, y: 0 },
  exit: { opacity: 0, y: 10 },
  transition: { duration: 0.2 },
};
