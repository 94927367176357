import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { verifyEmail } from "../../network/lib/validation";
import BGImg from "../../assets/images/bread_crumb_bg.png";

const Main = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [isLoading, setIsLoading] = useState(true);
  const [isSuccess, setIsSuccess] = useState(false);
  const [failureMessage, setFailureMessage] = useState(
    "There was an unexpected error. Please try again later."
  );

  useEffect(() => {
    if (searchParams.get("token") === null) {
      setFailureMessage(
        "You must verify your email with the verification email sent."
      );
      setIsLoading(false);
      setIsSuccess(false);
    } else {
      verifyEmail(searchParams.get("token"))
        .then(() => {
          setIsSuccess(true);
        })
        .catch((error) => {
          if (error.response.status === 404) {
            setFailureMessage(
              "Your verification code is invalid. Please sign in to your account and request a new verification code."
            );
          } else if (error.response.status === 400) {
            setFailureMessage(
              "Your verification code has expired. Please sign in to your account and request a new verification code."
            );
          }
          setIsSuccess(false);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [searchParams]);

  return (
    <>
      <div className="bred_crumb" style={{ backgroundImage: `url(${BGImg})` }}>
        <div className="container">
          <span className="banner_shape1">
            {" "}
            <img src="assets/images/banner-shape1.png" alt="" />
          </span>
          <span className="banner_shape2">
            {" "}
            <img src="assets/images/banner-shape2.png" alt="" />
          </span>
          <span className="banner_shape3">
            {" "}
            <img src="assets/images/banner-shape3.png" alt="" />
          </span>

          <div className="bred_text">
            {isLoading && (
              <>
                <h1>Verifying Email</h1>
                <p>Hang Tight!</p>
                <span class="loader"></span>
              </>
            )}
            {!isLoading && isSuccess && (
              <>
                <h1>Success!</h1>
                <p>
                  Thank you for verifying your email with us!
                  {/*We will send you
                  an email once your application has been reviewed and your
                  account is ready for access! */}
                </p>
              </>
            )}
            {!isLoading && !isSuccess && (
              <>
                <h1>Uh Oh!</h1>

                <p>{failureMessage}</p>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Main;
