import React from "react";
import { Link } from "react-router-dom";
import Bredcrumb from "../Bredcrumb/Main";
import BGImg from "../../assets/images/bread_crumb_bg.png";
import { Input } from "../Form/Input";
import { FormProvider, useForm } from "react-hook-form";
import ReactPixel from "react-facebook-pixel";

import {
  description_validation,
  email_validation,
  subject_validation,
} from "../../util/formValidations";
import { useState } from "react";
import { BsFillCheckCircleFill } from "react-icons/bs";
import { BsFillXCircleFill } from "react-icons/bs";
import { attemptContact } from "../../network/lib/forms";

const Main = () => {
  const methods = useForm();
  const [success, setSuccess] = useState(false);
  const [failure, setFailure] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const sendQuestion = async (data) => {
    attemptContact(data)
      .then(() => {
        setIsLoading(false);
        setSuccess(true);
        methods.reset();
        ReactPixel.track("Contact");
      })
      .catch((error) => {
        setIsLoading(false);
        setFailure(true);
      });
  };

  const onSubmit = methods.handleSubmit((data) => {
    setIsLoading(true);
    setSuccess(false);
    setFailure(false);
    sendQuestion(data);
  });

  return (
    <>
      <Bredcrumb
        no={1}
        title="Contact Us"
        paragraph="Have a question or need help? Fill out the information below and we will get back to you within 48 hours!"
        tag="Contact Us"
        bgimg={BGImg}
      />

      <section className="contact_page_section" id="contact">
        <div className="container">
          <div className="contact_inner">
            <div className="contact_form">
              <div className="section_title">
                <h2>
                  Have a <span>Question?</span>
                </h2>
                <p>
                  Fill out the form below and our team <br /> will get back to
                  you shortly
                </p>
              </div>
              <FormProvider {...methods}>
                <form onSubmit={(e) => e.preventDefault()} noValidate>
                  <div className="form-group">
                    <Input {...email_validation} />
                  </div>
                  <div className="form-group">
                    <Input {...subject_validation} />
                  </div>
                  <div className="form-group">
                    <Input
                      {...description_validation}
                      placeholder="What's up?"
                    />
                  </div>

                  {success && (
                    <div className="success">
                      <p>
                        <BsFillCheckCircleFill /> Question has been submitted
                        succesfully
                      </p>
                    </div>
                  )}
                  {failure && (
                    <div className="failure">
                      <p>
                        <BsFillXCircleFill /> A problem occured. Please try
                        again.
                      </p>
                    </div>
                  )}
                  <div className="form-group mb-0">
                    <button
                      disabled={isLoading}
                      onClick={onSubmit}
                      className="btn puprple_btn"
                    >
                      {isLoading ? (
                        <span class="loader"></span>
                      ) : (
                        <> SEND MESSAGE</>
                      )}
                    </button>
                  </div>
                </form>
              </FormProvider>
            </div>
            <div className="contact_info">
              <div className="icon">
                <img src="assets/images/contact_message_icon.png" alt="" />
              </div>
              <div className="section_title">
                <h2>
                  <span>Question?</span>
                </h2>
                <p>
                  Need something quick? Check out our{" "}
                  <Link to="/faq">FAQs page</Link> for all common questions.
                </p>
              </div>
              <Link to="/faq" className="btn puprple_btn">
                READ FAQ
              </Link>
              <ul className="contact_info_list">
                <li>
                  <div className="img">
                    <img src="assets/images/mail_icon.png" alt="" />
                  </div>
                  <div className="text">
                    <span>Email Us</span>
                    <Link to="#">contact@knowheresocial.com</Link>
                  </div>
                </li>
                <li>
                  <div className="img">
                    <img src="assets/images/call_icon.png" alt="" />
                  </div>
                  <div className="text">
                    <span>Call Us</span>
                    <Link to="#">+1 (720) 248-7257</Link>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <section className="row_am map_section">
        <div className="container">
          <div className="map_inner">
            <iframe
              title="ContactMap"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d41869.52680872931!2d-105.289399634734!3d40.02317409745742!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x876b8d4e278dafd3%3A0xc8393b7ca01b8058!2sBoulder%2C%20CO!5e0!3m2!1sen!2sus!4v1684383431958!5m2!1sen!2sus"
              width="100%"
              height="510"
              style={{ border: "0" }}
              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
        </div>
      </section>
    </>
  );
};

export default Main;
