import React, { useState } from "react";
import { Link } from "react-router-dom";
import Bredcrumb from "../Bredcrumb/Main";
import BGImg from "../../assets/images/bread_crumb_bg.png";
import { FormProvider, useForm } from "react-hook-form";
import {
  city_validation,
  companyName_validation,
  description_validation,
  email_validation,
  nonProfit_validation,
  password_validation,
  phone_validation,
  street1_validation,
  street2_validation,
  website_validation,
  zip_validation,
} from "../../util/formValidations";
import { Input } from "../Form/Input";
import { BsFillCheckCircleFill } from "react-icons/bs";
import { BsFillXCircleFill } from "react-icons/bs";
import { attemptSignUp } from "../../network/lib/forms";
import ReactPixel from "react-facebook-pixel";
import Select from "react-select";
import { stateData } from "../../util/states";

const Main = () => {
  const methods = useForm();
  const [success, setSuccess] = useState(false);
  const [sentRequest, setSentRequest] = useState(false);
  const [failureMessage, setFailureMessage] = useState(
    "A problem occured. Please try again."
  );
  const [isLoading, setIsLoading] = useState(false);
  const [stateSelection, setStateSelection] = useState(stateData[5].value);

  const sendSignup = async (data) => {
    data.state = stateSelection;
    data.country = "US"; //Hardcode to United States as we are only availble in US
    attemptSignUp(data)
      .then(() => {
        setIsLoading(false);
        setSuccess(true);
        methods.reset();
        setSentRequest(true);
        ReactPixel.track("CompleteRegistration", {
          content_name: "Company Registration",
        });
      })
      .catch((error) => {
        setIsLoading(false);
        if (error.response) {
          if (error.response.status === 409) {
            setFailureMessage(
              "There is already an account associated to that email. Please try again."
            );
          }
        }
        setSuccess(false);
        setSentRequest(true);
      });
  };

  const onSubmit = methods.handleSubmit((data) => {
    setIsLoading(true);
    setSuccess(false);
    setSentRequest(false);
    sendSignup(data);
  });

  const getFormDetails = () => {
    if (sentRequest) {
      return (
        <>
          <div className="section_title">
            <h2>
              You are <span>Registered!</span>
            </h2>
            <p>
              You can start promoting your events once you verify your email
              with us and are approved! In the meantime, finish setting up your
              business profile at your dashboard below.
            </p>
            <div className="form-group mb-0">
              <button
                onClick={() => {
                  window.location = "https://dashboard.knowheresocial.com";
                }}
                className="btn puprple_btn"
              >
                Dashboard
              </button>
            </div>
          </div>
        </>
      );
    } else {
      return (
        <>
          <div className="section_title">
            <h2>
              Let's get <span>Registered</span>
            </h2>
            <p>
              Fill out the form below and our team <br /> will get back to you
              shortly
            </p>
          </div>
          <FormProvider {...methods}>
            <form onSubmit={(e) => e.preventDefault} noValidate>
              <div className="form-group">
                <Input {...email_validation} />
              </div>
              <div className="form-group">
                <Input {...password_validation} />
              </div>
              <div className="form-group">
                <Input {...companyName_validation} />
              </div>
              <div className="form-group">
                <Input {...street1_validation} />
              </div>
              <div className="form-group">
                <Input {...street2_validation} />
              </div>
              <div className="horizontal-form-group">
                <div className="form-group">
                  <Input {...city_validation} />
                </div>
                <div className="form-group">
                  <Select
                    theme={(theme) => ({
                      ...theme,
                      colors: {
                        ...theme.colors,
                        primary: "#603AE9",
                        neutral30: "#603AE9",
                      },
                    })}
                    styles={{
                      control: (baseStyles, state) => ({
                        ...baseStyles,
                        height: "60px",
                        padding: "5px 20px",
                        color: state.isFocused ? "#603AE9" : "#E1DBF4",
                        border: `2px solid`,
                        borderRadius: "12px",
                        fontWeight: 500,
                      }),
                      option: (
                        styles,
                        { data, isDisabled, isFocused, isSelected }
                      ) => {
                        return {
                          ...styles,
                          backgroundColor: isSelected
                            ? "#603AE9"
                            : isFocused
                            ? "#E1DBF4"
                            : undefined,
                        };
                      },
                    }}
                    defaultValue={stateData[5]}
                    placeholder="State"
                    options={stateData}
                    onChange={(val) => setStateSelection(val.value)}
                  />
                </div>
              </div>
              <div className="horizontal-form-group">
                <div className="form-group">
                  <Input {...zip_validation} />
                </div>
                <div className="form-group">
                  <input
                    disabled
                    type="text"
                    className="form-control"
                    value="United States"
                  />
                </div>
              </div>
              <div className="form-group">
                <Input {...phone_validation} />
              </div>
              <div className="form-group">
                <Input {...website_validation} />
              </div>
              <div className="form-group">
                <Input
                  {...description_validation}
                  placeholder="Anything else?"
                  validation={{
                    required: {
                      value: false,
                    },
                  }}
                />
              </div>
              <Input {...nonProfit_validation} />
              {sentRequest && (
                <>
                  {!isLoading && success && (
                    <div className="success">
                      <p>
                        <BsFillCheckCircleFill /> Application has been submitted
                        succesfully
                      </p>
                    </div>
                  )}
                  {!isLoading && !success && (
                    <div className="failure">
                      <BsFillXCircleFill size={28} />
                      {failureMessage}
                    </div>
                  )}
                </>
              )}

              <div className="form-group mb-0">
                <button
                  disabled={isLoading}
                  onClick={onSubmit}
                  className="btn puprple_btn"
                >
                  {isLoading ? <span class="loader"></span> : <> Sign Up</>}
                </button>
              </div>
            </form>
          </FormProvider>
        </>
      );
    }
  };

  return (
    <>
      <Bredcrumb
        no={1}
        title="Sign Up"
        paragraph="Want to get access for your company? Apply now and we will get back to you within 48 hours!"
        tag="Sign Up"
        bgimg={BGImg}
      />

      <section
        className="contact_page_section"
        id="contact"
        style={{ marginTop: sentRequest ? "150px" : "" }}
      >
        <div className="container">
          <div className="contact_inner">
            <div className="contact_form">{getFormDetails()}</div>
            <div className="contact_info">
              <div className="icon">
                <img src="assets/images/contact_message_icon.png" alt="" />
              </div>
              <div className="section_title">
                <h2>
                  Have a <span>question?</span>
                </h2>
                <p>
                  If you have any questions about our product, service, payment
                  or company, Visit our <Link to="/faq">FAQs page</Link>. Or
                  head over to our <Link to="/contact">Contact Page</Link>.
                </p>
              </div>
              <Link to="/faq" className="btn puprple_btn">
                READ FAQ
              </Link>
              <ul className="contact_info_list">
                <li>
                  <div className="img">
                    <img src="assets/images/mail_icon.png" alt="" />
                  </div>
                  <div className="text">
                    <span>Email Us</span>
                    <Link to="#">contact@knowheresocial.com</Link>
                  </div>
                </li>
                <li>
                  <div className="img">
                    <img src="assets/images/call_icon.png" alt="" />
                  </div>
                  <div className="text">
                    <span>Call Us</span>
                    <Link to="#">+1 (720) 248-7257</Link>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <section className="row_am map_section">
        <div className="container">
          <div className="map_inner">
            <iframe
              title="Signup Map"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d41869.52680872931!2d-105.289399634734!3d40.02317409745742!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x876b8d4e278dafd3%3A0xc8393b7ca01b8058!2sBoulder%2C%20CO!5e0!3m2!1sen!2sus!4v1684383431958!5m2!1sen!2sus"
              width="100%"
              height="510"
              style={{ border: "0" }}
              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
        </div>
      </section>
    </>
  );
};

export default Main;
